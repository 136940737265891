.success-snackbar {
  border-radius: 4px;
  padding: 8px;
  border-left: 4px solid #12af23;
  background-color: #2c2f3d;
}

.error-snackbar {
  border-radius: 4px;
  padding: 8px;
  border-left: 4px solid #ff4d4d;
  background-color: #2c2f3d;
}
